import { NavItemI } from '../../../../core/shared/interfaces/general/nav-item.interface';

export const SIDE_MENU_ITEMS: NavItemI[] = [
  {
    translationPath: 'sideMenu.dashboard',
    activeIcon: '/svgs/side-menu/active-home.svg',
    nonActiveIcon: '/svgs/side-menu/none-active-home.svg',
    route: '',
    id: 1,
    alt: 'Dashboard',
  },

  {
    translationPath: 'sideMenu.patientsManagement',
    activeIcon: '/svgs/side-menu/active-patients.svg',
    nonActiveIcon: '/svgs/side-menu/none-active-patient.svg',
    route: 'patients-management',
    id: 2,
    alt: 'Patients Management',
  },
  {
    translationPath: 'sideMenu.doctorsManagement',
    activeIcon: '/svgs/side-menu/active-doctor.svg',
    nonActiveIcon: '/svgs/side-menu/none-active-doctor.svg',
    route: 'doctors-management',
    id: 3,
    alt: 'Patients Management',
  },
  {
    translationPath: 'sideMenu.citiesManagement',
    activeIcon: '/svgs/side-menu/active-cities.svg',
    nonActiveIcon: '/svgs/side-menu/none-active-cities.svg',
    route: 'cities-management',
    id: 4,
    alt: 'Cities Management',
  },
  {
    translationPath: 'sideMenu.categories',
    activeIcon: '/svgs/side-menu/active-categories.svg',
    nonActiveIcon: '/svgs/side-menu/none-active-categories.svg',
    route: 'categories',
    id: 5,
    alt: 'Categories',
  },
  {
    translationPath: 'sideMenu.subCategories',
    activeIcon: '/svgs/side-menu/active-sub-categories.svg',
    nonActiveIcon: '/svgs/side-menu/none-active-sub-categories.svg',
    route: 'sub-categories',
    id: 6,
    alt: 'Sub Categories',
  },
  {
    translationPath: 'sideMenu.seeks',
    activeIcon: '/svgs/side-menu/active-seek.svg',
    nonActiveIcon: '/svgs/side-menu/none-active-seek.svg',
    route: 'seeks-management',
    id: 7,
    alt: 'Seeks',
  },
];
