<aside class="side-menu" [style.width]="this.isSideMenuOpen ? '200px' : '60px'">
  <figure>
    <img src="/svgs/app/whole-logo-icon.svg" alt="Dr Uber logo" />
  </figure>

  <nav class="main-nav-list">
    <ul class="links">
      @for (item of sideMenuItems; track $index) { @if (!item.subRoutes) {
      <li
        (mouseenter)="this.itemOnHover = item.id"
        (mouseleave)="this.itemOnHover = -1"
        [routerLink]="item.route"
        class="link"
        [ngClass]="{ active: this.isCurrentRouteActive(item) }"
        [style.justify-content]="this.isSideMenuOpen ? 'flex-start' : 'center'"
      >
        <img [src]="this.getSideMenuItemIcon(item)" [alt]="item.alt" />
        <a [hidden]="!this.isSideMenuOpen">{{
          item.translationPath | translate
        }}</a>
      </li>
      } @else {

      <li
        class="link"
        [style.justify-content]="
          this.isSideMenuOpen ? 'space-between' : 'center'
        "
        (mouseenter)="this.itemOnHover = item.id"
        (mouseleave)="this.itemOnHover = -1"
        (click)="
          this.subRoutesToShow = item.id === this.subRoutesToShow ? -1 : item.id
        "
      >
        <div>
          <img [src]="this.getSideMenuItemIcon(item)" [alt]="item.alt" />
          <a [hidden]="!this.isSideMenuOpen">{{
            item.translationPath | translate
          }}</a>
        </div>
        @if (this.isSideMenuOpen) {
        <figure>
          <img
            [src]="
              '/svgs/side-menu/' +
              (itemOnHover === item.id ? 'white-' : '') +
              'down-arrow.svg'
            "
            alt=""
          />
        </figure>
        }
      </li>
      @if (subRoutesToShow === item.id && this.isSideMenuOpen) { @for (subItem
      of item.subRoutes; track $index) {
      <li
        class="sub-link"
        (mouseenter)="this.itemOnHover = subItem.id"
        (mouseleave)="this.itemOnHover = -1"
        [routerLink]="subItem.route"
      >
        <img
          [src]="this.getSideMenuItemIcon(item, subItem)"
          [alt]="subItem.alt"
        />
        <a [hidden]="!this.isSideMenuOpen">{{
          subItem.translationPath | translate
        }}</a>
      </li>
      } }}}
    </ul>
  </nav>
  <hr style="width: 82%; margin: 0 auto" />
  <nav class="secondary-nav-list">
    <ul>
      <li (click)="translate()">
        <a
          class="secondary-side-menu-item"
          [style.width]="this.isSideMenuOpen ? '90%' : 'fit-content'"
        >
          <img
            [src]="
              '/svgs/side-menu/' +
              (this.isRtl() ? 'en' : 'ar') +
              '-translate.svg'
            "
            alt="Translate"
          />

          <span [hidden]="!this.isSideMenuOpen">{{
            "sideMenu.translate" | translate
          }}</span>
        </a>
      </li>

      <li (click)="logout()">
        <a
          routerLinkActive="active"
          ariaCurrentWhenActive="page"
          class="secondary-side-menu-item"
          [style.width]="this.isSideMenuOpen ? '90%' : 'fit-content'"
        >
          <img src="/svgs/side-menu/logout.svg" alt="Logout" />

          <span [hidden]="!this.isSideMenuOpen">{{
            "sideMenu.logout" | translate
          }}</span>
        </a>
      </li>
    </ul>
  </nav>
</aside>
