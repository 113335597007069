import { Component, OnDestroy, OnInit } from '@angular/core';
import { RouterLink, RouterLinkActive, RouterOutlet } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { DoctorI } from '../../core/shared/interfaces/entities/doctor.interface';
import { Subject, takeUntil } from 'rxjs';
import { ToastService } from '../../core/services/toast/toast.service';
import { DoctorsApiService } from './doctors-api.service';
import { ApplicationStatus } from '../../core/shared/enums/application-status.enum';
import { DelegatedUIErrorI } from '../../core/shared/interfaces/general/delegated-ui-error.interface';

@Component({
  selector: 'app-doctors-management',
  standalone: true,
  imports: [RouterLink, RouterOutlet, RouterLinkActive, TranslateModule],
  templateUrl: './doctors-management.component.html',
  styleUrl: './doctors-management.component.scss',
})
export class DoctorsManagementComponent implements OnInit, OnDestroy {
  constructor(
    private readonly doctorsApiService: DoctorsApiService,
    private readonly toastService: ToastService
  ) {}
  doctors: DoctorI[] = [];
  count!: number;
  private readonly destroy$ = new Subject<void>();
  ngOnInit(): void {
    this.findAll();
  }
  findAll() {
    this.doctorsApiService
      .findAll({
        skip: 0,
        limit: 15,
        applicationStatus: ApplicationStatus.PENDING,
      })
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (findAllResponse) => {
          const { data } = findAllResponse;
          const { count, medicalServiceProviders } = data;
          this.doctors = medicalServiceProviders;
          this.count = count;
        },
        error: (err: DelegatedUIErrorI) => {
          this.toastService.showError(err.description, err.title);
        },
      });
  }
  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
