import { Injectable } from '@angular/core';
import { ApiService } from '../../../core/services/api/api.service';
import { ProfileI } from '../../../core/shared/interfaces/entities/profile.interface';

@Injectable({
  providedIn: 'root',
})
export class ProfileApiService {
  constructor(private readonly apiService: ApiService) {}
  findProfile() {
    return this.apiService.get<ProfileI>(`users/profile`);
  }
}
