import { Routes } from '@angular/router';
import { BaseComponent } from './views/base/base.component';
import { DoctorsManagementComponent } from './views/doctors-management/doctors-management.component';
import { loggedInGuard } from './core/guards/logged-in/logged-in.guard';
import { loggedOutGuard } from './core/guards/logged-out/logged-out.guard';

export const routes: Routes = [
  {
    path: '',
    component: BaseComponent,
    canActivate: [loggedInGuard],
    children: [
      {
        path: '',
        title: 'Dashboard',
        loadComponent: () =>
          import('./views/dashboard/dashboard.component').then(
            (m) => m.DashboardComponent
          ),
      },
      {
        path: 'patients-management',
        children: [
          {
            path: '',
            title: 'Patients Management',
            loadComponent: () =>
              import(
                './views/patients-management/patients-management.component'
              ).then((m) => m.PatientsManagementComponent),
          },
          {
            path: ':patientID',
            title: 'Patient Details',
            loadComponent: () =>
              import(
                './views/patients-management/patient-details/patient-details.component'
              ).then((m) => m.PatientDetailsComponent),
          },
        ],
      },
      {
        path: 'cities-management',
        title: 'Cities Management',
        loadComponent: () =>
          import('./views/cities-management/cities-management.component').then(
            (m) => m.CitiesManagementComponent
          ),
      },
      {
        path: 'categories',
        title: 'Categories',
        loadComponent: () =>
          import('./views/categories/categories.component').then(
            (m) => m.CategoriesComponent
          ),
      },
      {
        path: 'sub-categories',
        title: 'Sub Categories',
        loadComponent: () =>
          import('./views/sub-categories/sub-categories.component').then(
            (m) => m.SubCategoriesComponent
          ),
      },
      {
        path: 'doctors-management',
        component: DoctorsManagementComponent,
        children: [
          {
            path: 'accepted-doctors',
            title: 'Accepted Doctors',
            children: [
              {
                path: '',
                loadComponent: () =>
                  import(
                    './views/doctors-management/accepted-doctors/accepted-doctors.component'
                  ).then((m) => m.AcceptedDoctorsComponent),
              },
              {
                path: ':doctorID',
                title: 'Doctor Details',
                loadComponent: () =>
                  import(
                    './views/doctors-management/accepted-doctors/accepted-doctor-details/accepted-doctor-details.component'
                  ).then((m) => m.AcceptedDoctorDetailsComponent),
              },
            ],
          },
          {
            path: 'pending-doctors',
            title: 'Pending Doctors',
            children: [
              {
                path: '',
                loadComponent: () =>
                  import(
                    './views/doctors-management/pending-doctors/pending-doctors.component'
                  ).then((m) => m.PendingDoctorsComponent),
              },
              {
                path: ':doctorID',
                title: 'Doctor Details',
                loadComponent: () =>
                  import(
                    './views/doctors-management/pending-doctors/pending-doctor-details/pending-doctor-details.component'
                  ).then((m) => m.PendingDoctorDetailsComponent),
              },
            ],
          },
          {
            path: '**',
            redirectTo: 'pending-doctors',
            pathMatch: 'full',
          },
        ],
      },
      {
        path: 'seeks-management',
        children: [
          {
            path: '',
            title: 'Seeks Management',
            loadComponent: () =>
              import(
                './views/seeks-management/seeks-management.component'
              ).then((m) => m.SeeksManagementComponent),
          },
          {
            path: ':seekID',
            title: 'Seek details',
            loadComponent: () =>
              import(
                './views/seeks-management/seek-details/seek-details.component'
              ).then((m) => m.SeekDetailsComponent),
          },
        ],
      },
    ],
  },
  {
    path: 'auth',
    canActivate: [loggedOutGuard],
    children: [
      {
        path: 'login',
        title: 'Login',

        loadComponent: () =>
          import('./views/auth/login/login.component').then(
            (m) => m.LoginComponent
          ),
      },

      {
        path: 'forget-password',
        title: 'Forget Password',
        loadComponent: () =>
          import('./views/auth/forget-password/forget-password.component').then(
            (m) => m.ForgetPasswordComponent
          ),
      },
      {
        path: 'reset-password',
        title: 'Reset Password',
        loadComponent: () =>
          import('./views/auth/reset-password/reset-password.component').then(
            (m) => m.ResetPasswordComponent
          ),
      },
      {
        path: 'forget-password-verification',
        title: 'Forget Password Verification',
        loadComponent: () =>
          import(
            './views/auth/forget-password-verification/forget-password-verification.component'
          ).then((m) => m.ForgetPasswordVerificationComponent),
      },

      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'login',
      },
    ],
  },

  { path: '**', redirectTo: 'error/404', pathMatch: 'full' },
];
