import {
  AfterViewChecked,
  ChangeDetectorRef,
  Component,
  Inject,
  OnDestroy,
  OnInit,
  PLATFORM_ID,
} from '@angular/core';
import { Title } from '@angular/platform-browser';
import { SessionService } from '../../../core/services/session/session.service';
import { Subject, takeUntil } from 'rxjs';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { BREAK_POINTS } from '../../../core/shared/constants/break-points.constant';
import { UserI } from '../../../core/shared/interfaces/entities/user.interface';
import { ProfileI } from '../../../core/shared/interfaces/entities/profile.interface';
import { ProfileApiService } from './profile-api.service';
import { DelegatedUIErrorI } from '../../../core/shared/interfaces/general/delegated-ui-error.interface';
import { ToastService } from '../../../core/services/toast/toast.service';

@Component({
  selector: 'app-header',
  standalone: true,
  imports: [],
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss',
})
export class HeaderComponent implements OnInit, AfterViewChecked, OnDestroy {
  constructor(
    public readonly title: Title,
    private readonly sessionService: SessionService,
    @Inject(DOCUMENT) private document: Document,
    @Inject(PLATFORM_ID) private readonly platformID: Object,
    private readonly cd: ChangeDetectorRef,
    private readonly profileApiService: ProfileApiService,
    private readonly toastService: ToastService
  ) {}

  private readonly destroy$ = new Subject<void>();
  user: UserI | null = null;
  profile?: ProfileI;
  isSideMenuOpen!: boolean;

  ngAfterViewChecked(): void {
    this.user = this.sessionService.getUser();
    this.cd.detectChanges();
  }

  ngOnInit(): void {
    this.initIsSideMenuOpenListener();
    if (isPlatformBrowser(this.platformID))
      this.sessionService.addIsSideMenuOpen(
        this.document.defaultView!.innerWidth > BREAK_POINTS.mobile
      );

    this.findProfile();
  }
  findProfile() {
    this.profileApiService
      .findProfile()
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (findProfile) => {
          const { data } = findProfile;
          this.profile = data;
        },
        error: (err: DelegatedUIErrorI) => {
          this.toastService.showError(err.description, err.title);
        },
      });
  }

  initIsSideMenuOpenListener() {
    this.sessionService
      .getIsSideMenuOpen$()
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (isSideMenuOpen) => {
          this.isSideMenuOpen = isSideMenuOpen;
        },
      });
  }

  openCloseSideMenu() {
    this.sessionService.addIsSideMenuOpen(!this.isSideMenuOpen);
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
