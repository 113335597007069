import {
  DOCUMENT,
  isPlatformBrowser,
  NgClass,
  NgFor,
  NgIf,
} from '@angular/common';
import {
  Component,
  Inject,
  OnDestroy,
  OnInit,
  PLATFORM_ID,
} from '@angular/core';
import { NavigationEnd, Router, RouterLink } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { Subject, takeUntil } from 'rxjs';
import { ApiService } from '../../../core/services/api/api.service';
import { SessionService } from '../../../core/services/session/session.service';
import { StorageService } from '../../../core/services/storage/storage.service';
import { ToastService } from '../../../core/services/toast/toast.service';
import { TranslationService } from '../../../core/services/translate/translation.service';
import { BREAK_POINTS } from '../../../core/shared/constants/break-points.constant';
import { SIDE_MENU_ITEMS } from './constants/side-menu-items.constant';
import { Role } from '../../../core/shared/enums/role.enum';
import { ShowFullTextDirective } from '../../shared/directives/show-full-text/show-full-text.directive';
import { DelegatedUIErrorI } from '../../../core/shared/interfaces/general/delegated-ui-error.interface';
import { NavItemI } from '../../../core/shared/interfaces/general/nav-item.interface';

@Component({
  selector: 'app-side-menu',
  standalone: true,
  imports: [
    TranslateModule,
    RouterLink,
    NgFor,
    NgClass,
    ShowFullTextDirective,
    NgIf,
  ],
  templateUrl: './side-menu.component.html',
  styleUrl: './side-menu.component.scss',
})
export class SideMenuComponent implements OnInit, OnDestroy {
  sideMenuItems = SIDE_MENU_ITEMS;
  currentUrl: string = this.router.url.slice(1);
  isSideMenuOpen!: boolean;
  itemOnHover = -1;
  private readonly destroy$ = new Subject<void>();
  roles!: Role[];
  subRoutesToShow = -1;

  constructor(
    private readonly router: Router,
    private readonly sessionService: SessionService,
    private readonly storageService: StorageService,
    private readonly translationService: TranslationService,
    private readonly apiService: ApiService,
    private readonly toastService: ToastService,
    @Inject(DOCUMENT) private document: Document,
    @Inject(PLATFORM_ID) private readonly platformID: Object
  ) {}

  ngOnInit(): void {
    this.listenToRouteEvents();
    this.initIsSideMenuOpenListener();
    if (isPlatformBrowser(this.platformID))
      this.sessionService.addIsSideMenuOpen(
        this.document.defaultView!.innerWidth > BREAK_POINTS.mobile
      );

    //this.roles = this.sessionService.getDecodedToken().roles;
  }

  listenToRouteEvents(): void {
    this.router.events.pipe(takeUntil(this.destroy$)).subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.currentUrl = event.url.slice(1);
      }
    });
  }

  isRoleAllowedToItem(itemRoles?: Role[]) {
    if (!itemRoles) return true;
    return itemRoles.some((itemRole) => this.roles.includes(itemRole));
  }

  initIsSideMenuOpenListener() {
    this.sessionService
      .getIsSideMenuOpen$()
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (isSideMenuOpen) => {
          this.isSideMenuOpen = isSideMenuOpen;
        },
      });
  }

  public getSideMenuItemIcon(sideMenuItem: NavItemI, subitem?: NavItemI) {
    return sideMenuItem.route === this.currentUrl.split('/')[0] ||
      (sideMenuItem.alt === 'Dashboard' &&
        this.currentUrl.split('/')[0] === '') ||
      this.itemOnHover ===
        (!subitem ? this.sideMenuItems : sideMenuItem.subRoutes!).find(
          (item) =>
            item.translationPath ===
            (!subitem ? sideMenuItem.translationPath : subitem.translationPath)
        )?.id
      ? !subitem
        ? sideMenuItem.activeIcon
        : subitem.activeIcon
      : !subitem
      ? sideMenuItem.nonActiveIcon
      : subitem.nonActiveIcon;
  }

  public isCurrentRouteActive(sideMenuItem: NavItemI) {
    return (
      sideMenuItem.route === this.currentUrl.split('/')[0] ||
      (sideMenuItem.alt === 'Dashboard' && this.currentUrl === '')
    );
  }

  translate() {
    this.translationService.changeLanguage();
  }

  logout() {
    this.apiService
      .post('auth/logout', {})
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: () => {
          this.storageService.removeItem('accessToken');
          this.sessionService.setUser(null);
          this.router.navigate(['auth/login']);
        },
        error: (err: DelegatedUIErrorI) => {
          this.toastService.showError(err.description, err.title);
        },
      });
  }

  openCloseSideMenu() {
    this.sessionService.addIsSideMenuOpen(!this.isSideMenuOpen);
  }

  isRtl() {
    return this.storageService.language === 'ar';
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
