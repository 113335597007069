<h1>{{ "doctors.title" | translate }}</h1>
<br />
<div class="container">
  <figure
    class="button"
    routerLinkActive="active"
    routerLink="./accepted-doctors"
  >
    <p>{{ "doctors.acceptedDoc" | translate }}</p>
  </figure>
  <figure
    class="button"
    routerLink="./pending-doctors"
    routerLinkActive="active"
  >
    <p>
      {{ "doctors.pendingDoc" | translate }}
      <span class="pending">{{ count }}</span>
    </p>
  </figure>
</div>
<br />
<router-outlet></router-outlet>
